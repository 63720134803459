
<template>
  <div style="position: relative; right: -10px ">
    <div v-if="venta != null">
      <v-container
        v-show="posicion === 'baja' || venta.estructura[1].length === 0"
      >
        <v-row no-gutters class="giro fondo">
          <v-col
            v-for="(column, indexColumn) in venta.estructura[0]"
            :key="indexColumn"
            cols="2"
            sm="2"
            class="giro2"
            style="position: relative; "
          >
            <v-card
              v-if="venta.estructura[0].length"
              class="d-flex align-content-center flex-wrap"
              flat
              tile
            >
              <item-asiento-vuelta
                :addDetail="addDetail"
                :venta="venta"
                :ref="'item' + indexColumn.toString() + indexFila"
                :id="'item' + indexColumn.toString() + indexFila"
                v-model="venta.estructura[0][indexColumn][indexFila]"
                v-for="(fila, indexFila) in column"
                :key="indexColumn.toString() + indexFila"
              ></item-asiento-vuelta>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-show="posicion === 'alta' && venta.estructura[1].length != 0"
      >
        <v-row v-if="venta.estructura[1].length" no-gutters class="giro fondo">
          <v-col
            v-for="(column, indexColumn) in venta.estructura[1]"
            :key="indexColumn"
            cols="2"
            sm="2"
            class="giro2"
          >
            <v-card
              v-if="venta.estructura[1].length"
              class="d-flex align-content-center flex-wrap"
              flat
              tile
            >
              <item-asiento-vuelta
                :addDetail="addDetail"
                :venta="venta"
                :ref="'item' + indexColumn.toString() + indexFila"
                :id="'item' + indexColumn.toString() + indexFila"
                v-model="venta.estructura[1][indexColumn][indexFila]"
                v-for="(fila, indexFila) in column"
                :key="indexColumn.toString() + indexFila"
              ></item-asiento-vuelta>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <br />
    <v-container style="margin-bottom: 50px; margin-top: 50px; padding-left: 5px;">
      <v-row>
        <div class="titulo" align="left">
          <strong><h1>Confirma tus asientos</h1></strong>
        </div>
      </v-row>
      <br />
      <v-row>
        <div class="text">
        <h6 style="font-size: 16px " align="left" >
          Ya casi tienes los boletos para tu proximo viaje, tus asientos
          reservados son:
        </h6>
        </div>
      </v-row>
      <v-row
        class="text-sm-left"
        v-for="(detail, index) in boleto_details"
        :key="detail.id"
      >
        <v-col order="1" class="parent" v-if="detail.precio_lista !== 0">
          <button @click="deleteDetail(index)">
            <div class="icon">
              <i class="material-icons"> close </i>
            </div>
          </button>
        </v-col>
        <v-col order="2" style="margin-left: -100px">
          <img src="img/asientoAzul.png" class="asiento" />
        </v-col>
        <v-col order="3" class="precio" style="position: relative; left: -100px; top: 10px">
          <p>{{ detail.asiento + " " + "$" + detail.precio_lista }}</p>
        </v-col>
        <br />
      </v-row>
      <br />
      <div  v-if="boleto_details.length" class="aceptar" align="center" >
        <button v-if="allGreater" @click="validar()" >
          <App-button buttonText="Aceptar" />
        </button>
      </div>
    </v-container>
  </div>
</template>
<script>
import CryptoJS from 'crypto-js';
import axios from "axios";
import Titulo from "@/components/title.vue";
import Cancel from "@/components/ButtonCancel.vue";
import ItemAsientoVuelta from "@/components/ItemAsientoVuelta.vue";
import AppButton from "@/components/ButtonPrimary.vue";
export default {
  name: "Paso5",
  components: {
    Titulo,
    Cancel,
    ItemAsientoVuelta,
    AppButton,
  },
  props: {
    posicion: { required: false },
  },

  data: function () {
    return {
      //Subcorrida_id y corrida_id se obtiene al hacer click en seleccionar del componente ViajeIda y cuando es vuelta ViajeVuelta
      venta: null,
      boleto_details: [],
    };
  },
  created() {
    this.obtenerdatoslayout();
  },
  computed: {
     allGreater() {
  const allGreater = this.boleto_details.every((detail) => detail.precio_lista > 0);
  if (!allGreater) {
    this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "Elige otro asiento, este no puede ser elegido, tiene un precio de 0",
          });
  }
  return allGreater;
}
  },
  methods: {
    iniciar() {},

    async validar() {
      //console.log('entra')
      const token_id = localStorage.getItem("token_id");
      let asientos = [];
      for(let i=0; i < this.boleto_details.length; i++){
        asientos[i] = this.boleto_details[i].numero;
      };
      let options = {
        headers: {
          Accept: "application/vnd.api+json",
        },
      };
      axios.get(`/validarSeleccion?token_id=${token_id}&corrida_id=${this.boleto_details[0].corrida_id}&subcorrida_id=${this.boleto_details[0].subcorrida_id}&asientos=${asientos}`, options).then((response) => {
        if(response.data.success === true){
          this.$router.push({
            name: "paso5",
            params: { asientos_ida: this.asientos_ida },
          });
        }else{
          let asientos_empalmados = response.data.result;
          for(let i = 0; i < this.boleto_details.length; i++){
            for(let j = 0; j < asientos_empalmados.length; j++){
              if(Number(this.boleto_details[i].numero) === asientos_empalmados[j]){
                this.deleteDetail(i);
              }
            }
          }
        }
      });
    },
    //agrega asiento
    addDetail(asiento) {
      let categoriaNombre;
      if (asiento.categoria.nombre != undefined) {
        categoriaNombre = asiento.categoria.nombre.charAt(0);
      } else {
        categoriaNombre = asiento.categoria;
      }
      this.boleto_details.push({
        tipo_viaje: this.activeTab,
        classActive: false,
        asiento: asiento.numero + " " + categoriaNombre,
        numero: asiento.numero,
        es_blanco: 0,
        categoria: asiento.categoria,
        precio_lista: asiento.precio_en_linea,
        monto_canje: asiento.monto_canje,
        id: asiento.id,
        descuento: null,
        monto_descuento: 0,
        monto_total_pagar: parseFloat(asiento.precio_en_linea),
        tipo_viaje: "vuelta",
        nivel: this.posicion,
        corrida_id: localStorage.getItem("corrida_vuelta_id"),
        subcorrida_id: localStorage.getItem("subcorrida_vuelta_id")
      });

      let suma = this.boleto_details.reduce((acumulador, elemento) => {
        return acumulador + Number(elemento.precio_lista);
      }, 0);
      const encryptedSuma = this.encryptData(suma.toString());
      localStorage.setItem('key2', encryptedSuma);

      this.boleto_details.forEach((boleto) => {
      });
      /*
      this.boleto_details.forEach((boleto) => {
        localStorage.setItem("boleto_vuelta", JSON.stringify(this.boleto_details));
      });
      */
      this.$emit("eventBoletosVuelta", JSON.stringify(this.boleto_details));
      this.$emit("eventAsientoVuelta", this.boleto_details);
    },
    encryptData(data) {
  const secretKey = 'my-secret-key'; // clave secreta para el cifrado
  const encryptedData = CryptoJS.AES.encrypt(data, secretKey);
  return encryptedData.toString();
},
    //elimina asiento
    deleteDetail(index) {
      if (
        this.boleto_details[index].id != undefined &&
        this.boleto_details[index].tipo_viaje === "Ida"
      ) {
        const id = this.boleto_details[index].id;
        const item = this.$refs[id];
        if(this.boleto_details[index].nivel === 'baja'){
          item[0].resetAsiento();
        }else{
          if(item.length === 2){
            item[1].resetAsiento();
          }else{
            item[0].resetAsiento();
          }
        }
        this.boleto_details.splice(index, 1);
        this.$emit("eventAsientoVuelta", this.boleto_details);
        this.$emit("eventBoletosVuelta", JSON.stringify(this.boleto_details));
      } else if (
        this.boleto_details[index].id != undefined &&
        this.boleto_details[index].tipo_viaje === "Vuelta"
      ) {
        const id = this.boleto_details[index].id;
        const item = this.$refs[id];
        if(this.boleto_details[index].nivel === 'baja'){
          item[0].resetAsiento();
        }else{
          if(item.length === 2){
            item[1].resetAsiento();
          }else{
            item[0].resetAsiento();
          }
        }
        this.boleto_details.splice(index, 1);
        this.$emit("eventAsientoVuelta", this.boleto_details);
        this.$emit("eventBoletosVuelta", JSON.stringify(this.boleto_details));
      } else {
        const id = this.boleto_details[index].id;
        const item = this.$refs[id];
        if(this.boleto_details[index].nivel === 'baja'){
          item[0].resetAsiento();
        }else{
          if(item.length === 2){
            item[1].resetAsiento();
          }else{
            item[0].resetAsiento();
          }
        }
        this.boleto_details.splice(index, 1);
        this.$emit("eventAsientoVuelta", this.boleto_details);
        this.$emit("eventBoletosVuelta", JSON.stringify(this.boleto_details));
        this.boleto_details = this.boleto_details.filter(
          (boleto) => boleto.id !== id
        );
        /*
        this.boleto_details.forEach((boleto) => {
          localStorage.setItem("boleto_vuelta", JSON.stringify(this.boleto_details));
        });
        */
        let suma = this.boleto_details.reduce((acumulador, elemento) => {
          return acumulador + Number(elemento.precio_lista);
        }, 0);
         const encryptedSuma = this.encryptData(suma.toString());
      localStorage.setItem('key2', encryptedSuma);
      }
    },
    //obtiene subcorrida
    async obtenerdatoslayout() {
      const id = localStorage.getItem("subcorrida_vuelta_id");
      let options = {
        headers: {
          Accept: "application/vnd.api+json",
        },
      };
      axios
        .get(`/subcorridas/datosSubcorridaVentaCanje/${id}`, options)
        .then((response) => {
          this.venta = [];
          this.venta = response.data.data.attributes;
          this.$emit("eventVentaVuelta", this.venta);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
  },
};
</script>



<style scoped lang="less">
@media screen and (min-width: 100px) and (max-width: 599px) {
  .precio p{
    font-size: 20px !important;
    margin-left: -5px !important;
    margin-top: -20px !important;
  }
  .titulo {
    margin-left: 0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  .aceptar{
    margin-left: -45px;
  }
 .text{
  height: 70px;
    width: 320px;
    margin-left: 4px;
    margin-bottom: 20px;
      
 }
  .icon {
  font-weight: bold;
  margin-left: -12px;
  margin-top: -12px;
}
.parent button {
  margin-right: 100px;
  background-color: #f8fafc;
  color: rgb(48, 99, 139, 255);
  border-radius: 30px;
  border: 100px;
  padding: 20px;
  height: 30px;
  width: 30px;
  box-shadow: 0 2px 4px darkslategray;
  cursor: pointer;
  transition: all 0.2s ease;
}
.asiento { 
  height: 55px;
  width: 55px;
}
.fondo {
  height: 710px;
  width: 450px;
  margin-left: -25% !important;
  //margin-top: 100%;
  background-color: ffffff;
}
.giro {
  transform: rotate(180deg);
   background-color: none !important;
}
.giro2 {
  
  transform: rotate(180deg);
  background-color: none !important;
}

}
@media screen and (min-width: 600px) {
  .icon {
    font-weight: bold;
    margin-left: -12px;
    margin-top: -12px;
  }
  .parent button {
    margin-right: 100px;
    background-color: #f8fafc;
    color: rgb(48, 99, 139, 255);
    border-radius: 30px;
    border: 100px;
    padding: 20px;
    height: 30px;
    width: 30px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .asiento {
    height: 55px;
    width: 55px;
  }
  .fondo {
    margin-left: -120px;
    padding-left: 110px;
    background-color: ffffff;
  }
  .giro {
    transform: rotate(180deg);
    background-color: none !important;
  }
  .giro2 {
    transform: rotate(180deg);
    background-color: none !important;
  }
}
@media screen and (min-width: 1201px) {
  .icon {
    font-weight: bold;
    margin-left: -12px;
    margin-top: -12px;
  }
  .parent button {
    margin-right: 50px;
    background-color: #f8fafc;
    color: rgb(48, 99, 139, 255);
    border-radius: 30px;
    border: 100px;
    padding: 20px;
    height: 30px;
    width: 30px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .precio p{
    font-size: 20px !important;
    margin-left: -100px !important;
    margin-top: -20px !important;
  }
  .titulo {
    margin-left: 130px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-top: -30px !important;
  }
  .aceptar {
    margin-left: 0px;
  }
  .text {
    height: 70px;
    width: 500px;
    margin-left: 54px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1366px) {
  .icon {
    font-weight: bold;
    margin-left: -12px;
    margin-top: -12px;
  }
  .parent button {
    margin-right: 50px;
    background-color: #f8fafc;
    color: rgb(48, 99, 139, 255);
    border-radius: 30px;
    border: 100px;
    padding: 20px;
    height: 30px;
    width: 30px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .precio p{
    font-size: 20px !important;
    margin-left: -100px !important;
    margin-top: -20px !important;
  }
  .titulo {
    margin-left: 120px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-top: -30px !important;
  }
  .aceptar {
    margin-left: 0px;
  }
  .text {
    height: 70px;
    width: 500px;
    margin-left: 54px;
    margin-bottom: 20px;
  }
}
</style>
