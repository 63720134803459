<template>
  <div>
    <v-col>
      <Stepper4 />
    </v-col>
    <v-row>
      <div class="title">
        <InfoViaje
          textTitulo="Seleccionar tus asientos"
          subTitulo1=""
          subTitulo3=""
          
        />
      </div>
      <div class="back">
        <router-link to="/paso3">
          <button>
            <Back />
          </button>
        </router-link>
      </div>
    </v-row>
    <div class="componente">
      <div v-if="ventaVuelta != null && ventaVuelta.estructura[1].length != 0">
        <div class="toogle">
          <Toggle
            :ancho="true"
            buton1="Planta Alta"
            buton2="Planta Baja"
            @eventToogle="updatePlanta"
          />
        </div>
      </div>
      <div v-else>
        <div style="margin-top: -115px !important"></div>
      </div>
      <div v-if="ventaVuelta != null" class="servicios">
          <Servicios :venta="ventaVuelta" />
      </div>
      <br />
      <div class="box">
        <AsientosVuelta
          :posicion="posicion"
          @eventVentaVuelta="getVentaVuelta"
          :data="ventaVuelta"
          @eventAsientoVuelta="getAsientosVuelta"
          @eventBoletosVuelta="getBoletosVuelta"
          :asientos="boleto_details"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .title {
    margin-top: 10%;
    text-align: left;
    margin-left: 6%;
  }

  .aceptar {
    margin-top: 30px !important;
  }
  .back {
    margin-left: 78%;
    margin-top: -33%;
  }

  .toogle{
    padding-left: 20px;
  }
  // fondo de los asientos
  .box {
    margin-right: 5%;
    margin-left: 5%;
    height: auto;
    width: 410px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .componente {
    margin-top: -20%;
  }
  .servicios{
    margin-top: 5%;
    padding-left: 10px;
  }

}
@media screen and (min-width: 481px) and (max-width: 800px) {
  .title {
    margin-top: 10%;

    text-align: left;
    margin-left: 6%;
  }

  .aceptar {
    margin-top: 30px;
    margin-left: -12%;
  }
  .back {
    margin-left: 78px;
    margin-top: 80px;
  }
  // fonodo de los asientos
  .box {
    margin-right: -5%;
    margin-left: 15%;
    height: 80px;
    width: 500px;
  }
  .toogle {
    margin-left: 17%;
    
  }
  .servicios{
    margin-top: 120px;
  }
  .componente {
    margin-top: -28%;
  }
}
@media screen and (min-width: 802px) and (max-width: 870px) {
  .title {
    margin-top: -2%;

    text-align: left;
    margin-left: 6%;
  }

  .aceptar {
    margin-top: 30px;
    margin-left: -12%;
  }
  .back {
    margin-left: 78px;
    margin-top: 80px;
  }
  // fonodo de los asientos
  .box {
    margin-right: 5%;
    margin-left: 15%;
    height: 880px;
    width: 500px;
  }
  .toogle {
     margin-left: 17%;
  }
  .servicios{
    margin-top: 190px;
  }
  .componente {
    margin-top: -28%;
  }
}
@media screen and (min-width: 871px) and (max-width: 1280px) {
  .title {
    margin-top: -2%;

    text-align: left;
    margin-left: 6%;
  }

  .aceptar {
    margin-top: 30px;
  }
  .back {
    margin-left: 78px;
    margin-top: 60px;
  }
  // fonodo de los asientos
  .box {
    margin-right: 5%;
    margin-left: 22%;
    height: 880px;
    width: 500px;
  }
  .toogle {
    margin-left: 22%;
  }
  .servicios{
    margin-top: 190px;
  }
  .componente {
    margin-top: -20%;
  }
}
@media screen and (min-width: 1201px)  {
  .title {
    margin-top: -2%;
    text-align: left;
    margin-left: 6%;
  }

  .aceptar {
    margin-top: 30px;
  }
  .back {
    margin-left: 78px;
    margin-top: -30px;
  }
  // fondo de los asientos
  .box {
    margin-top: -25px !important;
    margin-right: 5%;
    margin-left: 29%;
    height: 880px;
    width: 500px;
  }
  .toogle {
    margin-left: 30%;
  }
  .servicios{
    margin-top: 190px;
    margin-left: 130px;
  }
  .componente {
    margin-top: -18%;
  }
}
@media screen and (min-width: 1366px)  {
  .title {
    margin-top: -2%;
    text-align: left;
    margin-left: 6%;
  }

  .aceptar {
    margin-top: 30px;
  }
  .back {
    margin-left: 78px;
    margin-top: -30px;
  }
  // fondo de los asientos
  .box {
    margin-top: -25px !important;
    margin-right: 5%;
    margin-left: 30%;
    height: 880px;
    width: 500px;
  }
  .toogle {
    margin-left: 30%;
  }
  .servicios{
    margin-top: 190px;
    margin-left: 0px;
  }
  .componente {
    margin-top: -18%;
  }
}
@media screen and (min-width: 1920px)  {
  .title {
    margin-top: -2%;
    text-align: left;
    margin-left: 6%;
  }

  .aceptar {
    margin-top: 30px;
  }
  .back {
    margin-left: 78px;
    margin-top: -40px;
  }
  // fondo de los asientos
  .box {
    margin-top: -25px !important;
    margin-right: 5%;
    margin-left: 35%;
    height: 880px;
    width: 500px;
  }
  .toogle {
    margin-left: 32%;
  }
  .servicios{
    margin-top: 190px;
  }
  .componente {
    margin-top: -13%;
  }
}
</style>

<script>
import axios from "axios";
import InfoViaje from "@/components/InfoViaje.vue";
import Back from "@/components/Back.vue";
import Seleccionado from "@/components/ButtonPrimary.vue";
import Stepper4 from "@/components/Stepper/Stepper4.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Toggle from "@/components/Toogle2.vue";
import Titulo from "@/components/title.vue";
import Cancel from "@/components/ButtonCancel.vue";
import AsientosVuelta from "@/components/AsientosVuelta.vue";
import Amenidades from "@/components/Amenidades.vue";
import Servicios from "@/components/Servicios.vue";
import ConfirmarAsiento from "@/components/ConfirmarAsientos.vue";

export default {
  name: "HomeView",
  props:{
    boletos_vuelta: { required: false }
  },
  data() {
    return {
      posicion: "alta",
      ventaVuelta: null,
      boleto_details: null,
      asientos_vuelta: [],
      boletos_v: '',
    };
  },
  components: {
    AppButton,
    InfoViaje,
    Back,
    Seleccionado,
    Stepper4,
    Toggle,
    Titulo,
    Cancel,
    Amenidades,
    Servicios,
    ConfirmarAsiento,
    AsientosVuelta
  },
  created(){
    this.boletos_v = JSON.parse(this.boletos_vuelta);
    if(this.boletos_v != 'null'){
      this.desbloquearAsientos(this.boletos_v)
    }else{
      this.boletos_v = null;
    }
  },
  mounted() {
    const value = localStorage.getItem("value");
    if (value == 1) {
      //El viaje es sencillo
      this.$router.push("/paso2");
    } else {
      //El viaje es redondo
     
    }
  },
   methods: {
    iniciar() {},
    //guarda posicion de toogle
    updatePlanta() {
      this.posicion = localStorage.getItem("posicion");
    },
    //guarda datos de venta subcorrida obtenida
    getVentaVuelta(data) {
      this.ventaVuelta = data;
    },
    //guarda asientos de ida seleccionados
    getAsientosVuelta(asientos) {
      this.asientos_vuelta = asientos;
    },
    getBoletosVuelta(data){
      this.$emit("eventBoletosVuelta", data);
    },
    async desbloquearAsientos(boletos){
      if(boletos != null && boletos.length > 0){
        for(let i=0; i < boletos.length; i++){
          
          let data = {
            data: {
              token_id: localStorage.getItem("token_id"),
              corrida_id: boletos[i].corrida_id,
              subcorrida_id: boletos[i].subcorrida_id,
              numero_asiento: boletos[i].numero,
              tipo: 'deseleccion'
            },
          };
          try {
          const options = {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json"
            }
          };
          axios
            .post("/seleccionarAsiento", data, options).then((response) => {
              })
          }catch (error) {
            console.log(error);
          }
        }
        //localStorage.setItem('boleto', null);
      }
    },

  },
};
</script>