<template>
  <div class="body" :class="bodyVariable">
    <div v-if="!iframe" class="row flex items-center">
      <div class="col-8"></div>
      <div class="col-4 flex">
        <a href="https://www.facebook.com/apoloplatinum" target="_blank"><img src="/images/facebook.png" width="40" style="padding-right: 7px !important;"></a>
        <a href="https://www.instagram.com/apoloplatinum" target="_blank"><img src="/images/instagram.png" width="40" style="padding-right: 7px !important;"></a>
        <a href="https://www.youtube.com/@apoloplatinum6490" target="_blank"><img src="/images/youtube.png" width="40" style="padding-right: 7px !important;"></a>
        <a href="https://www.tiktok.com/@apoloplatinum" target="_blank"><img src="/images/tiktok.png" width="40" style="padding-right: 7px !important;"></a>
      </div>
    </div>
    <!--
    <iframe src="http://localhost:8080/" width="430" height="450"></iframe>
    -->
    <div v-if="!iframe" id="appp" class="row mb-10 bienvenido">
      <div class="col-12 text-center">
        <h1>Tu mejor viaje <br>Bienvenido</h1>
      </div>
    </div>
    <div class="pt-0 mb-10 mt-0">
      <div class="grid place-items-center text-center" :class="sencilloredondo">
        <div
          class="switch-button"
          id="switch-button"
          @click="click()"
          :class="['largo']"
        >
          <div
            class="switch-item"
            @click="
              clickRedondo();
              hide();
            "
          >
            Redondo
          </div>
          <div
            class="switch-item"
            @click="
              clickSencillo();
              hide();
            "
          >
            Sencillo
          </div>
        </div>
      </div>
    </div>

    <div class="container" :class="origenVariable" data-app>
      <div class="vamos text-left">
        <h4 :class="vamosVariable">¿A dónde vamos?</h4>
      </div>
      <div class="place-items-center text-center mt-0">
      <div class="box">
        <div class="origen">
          <h5>Origen</h5>
        </div>
        <div class="input1">
          <v-container fluid>
            <v-row>
              <v-col class="d-flex" cols="" sm="13">
                <select v-model="selectida" class="icon">
                  <option disabled value="">Selecciona un origen</option>
                  <option
                    v-for="item in ida"
                    :key="item.id2"
                    :value="[
                      item.id,
                      item.attributes.nombre,
                      item.attributes.ciudad.attributes.nombre,
                      item.attributes.abreviatura,
                    ]"
                  >
                    <template v-if="item.terminal != undefined">
                      {{ item.terminal }}
                    </template>
                    <template v-else>
                      {{ item.attributes.ciudad.attributes.nombre }}
                    </template>
                  </option>
                </select>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="hr">
          <hr />
        </div>
        <div class="Destino">
          <h5>Destino</h5>
        </div>
        <div class="input1">
          <v-container fluid>
            <v-row align="center">
              <v-col class="d-flex" cols="" sm="14">
                <select v-model="selectvuelta" class="icon">
                  <option disabled value="">Selecciona un destino</option>
                  <option
                    v-for="item in ida"
                    :key="item.id"
                    :value="[
                      item.id,
                      item.attributes.nombre,
                      item.attributes.ciudad.attributes.nombre,
                      item.attributes.abreviatura,
                    ]"
                  >
                    <template v-if="item.terminal != undefined">
                      {{ item.terminal }}
                    </template>
                    <template v-else>
                      {{ item.attributes.ciudad.attributes.nombre }}
                    </template>
                  </option>
                </select>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    </div>

    <div class="grid grid-cols-8">
      <div class="col-start-4 2xl:-mr-2 xl:mr-6 lg:-ml-32 -ml-48">
        <date-picker
        id="input-ida"
        v-model="date"
        name="fecha_ida"
        value-type="date"
        :lang="lang"
        placeholder="Ida"
        :disabled-date="notBeforeToday"
        format="YYYY-MM-DD"
        :confirm="true"
        :confirm-text="'ACEPTAR'"
      >
        <i slot="icon-calendar">
          <div class="icon-calendario">
            <img src="img/Calendario.png" />
          </div>
        </i>
      </date-picker>
      </div>
      <div class="2xl:ml-8 xl:ml-2 lg:ml-0 -ml-12" :class="pickerVueltaVariable" id="myDIV">
        <date-picker
          id="input-vuelta"
          v-model="date2"
          name="fecha_vuelta"
          value-type="date"
          :lang="lang"
          placeholder="Vuelta"
          :disabled-date="notBeforeToday"  
          :confirm="true"
          :confirm-text="'ACEPTAR'"
        >
          <i slot="icon-calendar">
            <div class="icon-calendario">
              <img src="img/Calendario.png" />
            </div>
          </i>
        </date-picker>
      </div>
    </div>
    <!--

    <div class="mt-5" :class="pickersVariable">
      <v-row>
          <date-picker
          id="input-ida"
          v-model="date"
          name="fecha_ida"
          value-type="date"
          :lang="lang"
          placeholder="Ida"
          :disabled-date="notBeforeToday"
          format="YYYY-MM-DD"
        >
          <i slot="icon-calendar">
            <div class="icon-calendario">
              <img src="img/Calendario.png" />
            </div>
          </i>
        </date-picker>
        <div :class="pickerVueltaVariable" id="myDIV">
          <date-picker
            id="input-vuelta"
            v-model="date2"
            name="fecha_vuelta"
            value-type="date"
            :lang="lang"
            placeholder="Vuelta"
            :disabled-date="notBeforeToday"  
          >
            <i slot="icon-calendar">
              <div class="icon-calendario">
                <img src="img/Calendario.png" />
              </div>
            </i>
          </date-picker>
        </div>
      </v-row>
    </div>
    -->
    <!--
    <div class="pasajeros">
      <br>
      <PasajeroCount />
    </div>
    -->
    <button
    class="mt-4"
      @click="
        validacion();
      "
    >
      <App-button buttonText="Buscar   " />
    </button>
    <div class="alert alert-danger" role="alert" v-if="error">
      {{ error_msg }}
    </div>
    <div v-if="!iframe" class="registrate">
      <router-link v-if="noSesion" to="registrate">
        <h4>Regístrate</h4>
      </router-link>
      <router-link v-if="noSesion" to="login">
        <h4>Inicia Sesión</h4>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@media screen and (max-width: 430px) {
  .bodyFondo2{
    background-color: #00448D !important;
  }
  .vamos2{
    margin-bottom: 0 !important;
    margin-top: -4% !important;
    color: white !important;
  }
  .origenDestino2{
    margin-top: -3% !important;
    margin-left: 0% !important;
  }
  .idaVuelta2{
    margin-bottom: 15px !important;
    margin-top: 25px !important;
    margin-left: 3% !important;
  }
  .vuelta2{
    margin-top: 0px !important;
    margin-left: -60px !important;
  }
  .sencilloredondo2{
    margin-left: 0% !important;
    margin-top: 1% !important;
    margin-bottom: 0% !important;
  }
  .bienvenido{
    margin-bottom: 5% !important;
  }
  .origenDestino{
    margin-top: -3% !important;
    margin-left: 1% !important;
  }
  .sencilloredondo{
    margin-right: 0% !important;
  }
  .idaVuelta{
    margin-left: -11% !important;
  }
  .switch-button {
    //margin-top: 5%;
    box-shadow: 1px 2px 3px;
    //margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    background: #f8fafc;
    display: flex;
    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }

  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }
  .vuelta {
    margin-left: -80%;
  }

  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }
  #appp {
    font-family: "Montserrat";
    font-size: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .container {
    margin: 0px auto;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 15px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 14px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 60%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
  }
  .vamos {
    font-family: "Montserrat";
    font-weight: 500;
    //margin-right: 24%;
    //margin-top: 21%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
   //margin-left: 15%;
    height: 130px;
    width: 100%;
    background-color: #f8fafc;
    //margin-top: -5%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    width: 280px;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;
    padding-left: 15%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 30px;
  }
  .input1 {
    width: 1%;

    font-size: 60%;
  }
  input {
    width: 10%;
    height: 17px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .d-flex {
    padding-left: 20px;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -10%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
}
@media screen and (min-width: 431px) and (max-width: 768px) {
  .bodyFondo2{
    //background-color: #FFFFFF !important;
    background-color: #00448D !important;
  }
  .vamos2{
    margin-bottom: 3% !important;
    color:#F7FAFC !important;
  }
  .origenDestino2{
    margin-top: -3% !important;
    margin-left: 12% !important;
  }
  .idaVuelta2{
    margin-top: 1% !important;
    margin-left: 13% !important;
  }
  .vuelta2{
    margin-left: -3%;
  }
  .bienvenido{
    margin-bottom: 5% !important;
  }
  .switch-button {
    //margin-top: 5%;
    box-shadow: 1px 2px 3px;
    //margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    background: #f8fafc;
    display: flex;
    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }

  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }
  .vuelta {
    margin-left: -16%;
  }

  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }
  #appp {
    font-family: "Montserrat";
    font-size: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .container {
    margin: 0px auto;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 15px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 14px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 60%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
  }
  .vamos {
    font-family: "Montserrat";
    font-weight: 500;
    //margin-right: 24%;
    //margin-top: 21%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    //margin-left: 15%;
    height: 130px;
    width: 100%;
    background-color: #f8fafc;
    //margin-top: -5%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    width: 280px;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;
    padding-left: 15%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 30px;
  }
  .input1 {
    width: 1%;

    font-size: 60%;
  }
  input {
    width: 10%;
    height: 17px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .d-flex {
    padding-left: 20px;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -10%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  .bodyFondo2{
    //background-color: #FFFFFF !important;
    background-color: #00448D !important;
  }
  .vamos2{
    margin-bottom: 1% !important;
    color:#F7FAFC !important;
  }
  .origenDestino2{
    margin-top: -3% !important;
    margin-left: 12% !important;
  }
  .idaVuelta2{
    margin-top: 1% !important;
    margin-left: 13% !important;
  }
  .vuelta2{
    margin-left: -3%;
  }
  .bienvenido{
    margin-bottom: 5% !important;
  }
  .origenDestino{
    margin-top: 7% !important;
    margin-left: 10% !important;
  }
  .idaVuelta{
    margin-top: 3% !important;
    margin-left: 10% !important;
  }

  #appp {
    font-family: "Montserrat";
    font-size: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    //margin-top: 5%;
    box-shadow: 1px 2px 3px;
    //margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }
  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }

  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }
  .container {
    margin: 0px auto;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 15px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 14px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 60%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  .vamos {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    //margin-right: 47%;
    //margin-top: 15%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -150px;
    height: 135px;
    width: 85%;
    background-color: #f8fafc;
    //margin-top: -5%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    width: 320px;
    margin-left: 2%;
    font-family: "Montserrat";
    font-weight: 500;

    background-color: red;

    padding-left: 12%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;
    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -6%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .vuelta {
    margin-left: -px;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
  .d-flex {
    margin-left: 20%;
  }
}
@media screen and (min-width: 993px) and (max-width: 1023px) {
  .bodyFondo2{
    //background-color: #FFFFFF !important;
    background-color: #00448D !important;
  }
  .vamos2{
    margin-bottom: -1% !important;
    color:#F7FAFC !important;
  }
  .origenDestino{
    margin-top: 3% !important;
    margin-left: 19% !important;
  }
  .idaVuelta{
    margin-top: 0% !important;
    margin-left: 10% !important;
  }
  .vuelta{
    margin-left: 3%;
  }
  .origenDestino2{
    margin-top: 3% !important;
    margin-left: 19% !important;
  }
  .idaVuelta2{
    margin-top: 0% !important;
    margin-left: 10% !important;
  }
  .vuelta2{
    margin-left: 3%;
  }
  .sencilloredondo2{
    margin-right: 20% !important;
  }
  .sencilloredondo{
    margin-right: 22% !important;
  }
  #appp {
    margin-top: 8%;
    margin-left: -40px;
    font-family: "Montserrat";
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    margin-top: 5%;
    box-shadow: 1px 2px 3px;
    margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }

  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }
  .container {
    margin: 1px auto;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }

  p {
    padding: 0px;
    font-size: 15px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 16px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 90%;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }

  .vamos {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    //margin-right: 25%;
    //margin-top: 15%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -170px;
    height: 135px;
    width: 580px;
    background-color: #f8fafc;
    //margin-top: -2%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -5%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    margin-left: 20%;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;

    padding-left: 19%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;
    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .bodyFondo2{
    //background-color: #FFFFFF !important;
    background-color: #00448D !important;
  }
  .vamos2{
    margin-bottom: -1% !important;
    color:#F7FAFC !important;
  }
  .origenDestino{
    margin-top: 3% !important;
    margin-left: 19% !important;
  }
  .idaVuelta{
    margin-top: 0% !important;
    margin-left: 10% !important;
  }
  .vuelta{
    margin-left: 3%;
  }
  .origenDestino2{
    margin-top: 3% !important;
    margin-left: 19% !important;
  }
  .idaVuelta2{
    margin-top: 0% !important;
    margin-left: 10% !important;
  }
  .vuelta2{
    margin-left: 3%;
  }
  .sencilloredondo2{
    margin-right: 20% !important;
  }
  .sencilloredondo{
    margin-right: 22% !important;
  }
  #appp {
    margin-top: 8%;
    margin-left: -40px;
    font-family: "Montserrat";
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    margin-top: 5%;
    box-shadow: 1px 2px 3px;
    margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }

  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }
  .container {
    margin: 1px auto;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }

  p {
    padding: 0px;
    font-size: 15px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 16px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 90%;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }

  .vamos {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    //margin-right: 25%;
    //margin-top: 15%;
    margin-left: 20px;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -380px;
    height: 135px;
    width: 580px;
    background-color: #f8fafc;
    //margin-top: -2%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -5%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    margin-left: 20%;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;

    padding-left: 19%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;
    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
  }
}
@media screen and (min-width: 1200px) {
  .bodyFondo2{
    //background-color: #FFFFFF !important;
    background-color: #00448D !important;
  }
  .vamos2{
    margin-bottom: -3% !important;
    color:#F7FAFC !important;
  }
  .origenDestino2{
    margin-top: -1% !important;
    margin-left: 25% !important;
  }
  .idaVuelta2{
    margin-top: 0% !important;
    margin-left: 17% !important;
    margin-bottom: -2% !important;
  }
  .vuelta2{
    margin-left: -1%;
  }
  .origenDestino{
    margin-top: 7% !important;
    margin-left: 25% !important;
  }
  .idaVuelta{
    margin-top: 3% !important;
    margin-left: 25% !important;
  }
  .sencilloredondo{
    margin-right: 22% !important;
  }
  .sencilloredondo2{
    margin-right: 20% !important;
  }
  #appp {
    //margin-left: -40px;
    font-family: "Montserrat";
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    margin-top: 5%;
    box-shadow: 1px 2px 3px;
    margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }
  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 25px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 18px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 90%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
  }
  .vamos {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 600;
    //margin-right: 28%;
    //margin-top: 15%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -430px;
    height: 135px;
    width: 580px;
    background-color: #f8fafc;
    //margin-top: -1%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 72%;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -5%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    margin-left: 20%;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;

    padding-left: 22%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;

    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
  .pasajeros {
    margin-left: 18%;
  }
}
@media screen and (min-width: 1366px) {
  .origenDestino{
    margin-top: 7% !important;
    margin-left: 28% !important;
  }
  .idaVuelta{
    margin-top: 3% !important;
    margin-left: 24% !important;
  }
  .sencilloredondo{
    margin-right: 20% !important;
  }
  .sencilloredondo2{
    margin-right: 20% !important;
  }
  #appp {
    margin-left: -10px !important;
    font-family: "Montserrat";
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    margin-top: 5%;
    box-shadow: 1px 2px 3px;
    margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }
  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 25px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 18px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 90%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
  }
  .vamos {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 600;
    //margin-right: 28%;
    //margin-top: 15%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -680px;
    height: 135px;
    width: 580px;
    background-color: #f8fafc;
    //margin-top: -1%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 72%;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -5%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    margin-left: 20%;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;

    padding-left: 22%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;

    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
  .pasajeros {
    margin-left: 18%;
  }
}
@media screen and (min-width: 1440px) {
  .origenDestino{
    margin-top: 7% !important;
    margin-left: 29% !important;
  }
  .idaVuelta{
    margin-top: 3% !important;
    margin-left: 24.5% !important;
  }
  .sencilloredondo{
    margin-right: 20% !important;
  }
  .sencilloredondo2{
    margin-right: 20% !important;
  }
  #appp {
    margin-left: -10px !important;
    font-family: "Montserrat";
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    margin-top: 5%;
    box-shadow: 1px 2px 3px;
    margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }
  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 25px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 18px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 90%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
  }
  .vamos {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 600;
    //margin-right: 28%;
    //margin-top: 15%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -680px;
    height: 135px;
    width: 580px;
    background-color: #f8fafc; 
    //margin-top: -1%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 72%;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -5%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    margin-left: 20%;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;

    padding-left: 22%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;

    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
  .pasajeros {
    margin-left: 18%;
  }
}
@media screen and (min-width: 1536px) {
  .origenDestino{
    margin-top: 7% !important;
    margin-left: 29% !important;
  }
  .idaVuelta{
    margin-top: 3% !important;
    margin-left: 24.5% !important;
  }
  .sencilloredondo{
    margin-right: 20% !important;
  }
  .sencilloredondo2{
    margin-right: 20% !important;
  }
  #appp {
    margin-left: -10px !important;
    font-family: "Montserrat";
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    margin-top: 5%;
    box-shadow: 1px 2px 3px;
    margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }
  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 25px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 18px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 90%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
  }
  .vamos {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 600;
    margin-left: 20px;
    //margin-right: 28%;
    //margin-top: 15%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -890px;
    height: 135px;
    width: 580px;
    background-color: #f8fafc; 
    //margin-top: -1%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 72%;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -5%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    margin-left: 20%;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;

    padding-left: 22%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;

    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
  .pasajeros {
    margin-left: 18%;
  }
}
@media screen and (min-width: 1920px) {
  .origenDestino{
    margin-top: 7% !important;
    margin-left: 34% !important;
  }
  .idaVuelta{
    margin-top: 3% !important;
    margin-left: 28.5% !important;
  }
  .sencilloredondo{
    margin-left: 0px !important;
  }

  #appp {
    margin-left: -5px !important;
    font-family: "Montserrat";
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-weight: 600;
  }
  .switch-button {
    margin-top: 5%;
    box-shadow: 1px 2px 3px;
    margin-left: 20%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;

    background: #f8fafc;
    display: flex;

    align-items: center;
    border-radius: 0.7rem;
  }
  .switch-button:before {
    content: "";
    position: absolute;
    cursor: pointer;
    left: -50%;
    top: -100%;
    height: 300%;
    width: 100%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
    border-radius: 47%;
  }
  .switch-item {
    position: relative;
    font-size: 16px;
    padding: 1.5rem;
    color: #aba6a9;
    font-weight: 500;
    cursor: pointer;
  }
  .switch-item:first-child {
    color: rgb(255, 255, 255);
  }
  .switch-button.switch-active .switch-item:first-child {
    font-size: 16px;
    color: #aba6a9;
  }
  .switch-button.switch-active .switch-item:last-child {
    color: #fff;
  }

  .switch-button.switch-active:before {
    left: 50%;
    background: linear-gradient(to top right, #0569a0, #0569a0);
  }
  .largo {
    height: 2.5rem;
    width: 220px;
  }
  .ancho {
    height: 2.7rem;
    width: 280px;
    padding: 5px;
    margin-left: 10%;
  }

  .container button {
    font-size: 15px;
    padding: 2px 28px;
    background-color: #30638b;
    color: #fff;
    border: 1px solid #30638b;
    border-radius: 10px;
    cursor: pointer;
  }
  .container button.selected {
    background-color: #3a9bcf;
    border-color: rgb(141, 22, 22);
  }
  p {
    padding: 0px;
    font-size: 25px;
    width: 150%;
    background-color: rgba(250, 248, 248);
    border: 0px;
    outline: 0px;
  }

  h5 {
    margin: 30px 0 0;
    font-size: 18px;
  }

  h3 {
    font-weight: 500;
    font-family: "Montserrat";
    margin: 30px 0 0;
    font-size: 90%;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
  }
  .vamos {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 600;
    //margin-right: 28%;
    //margin-top: 15%;
  }
  .box {
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    border-radius: 10px;
    margin-left: -940px;
    height: 135px;
    width: 580px;
    background-color: #f8fafc;
    //margin-top: -1%;
  }
  .origen {
    font-family: "Montserrat";
    color: #989295;
    font-weight: 500;
    margin-right: 72%;
  }
  .Destino {
    font-family: "Montserrat";
    margin-top: -5%;
    color: #989295;
    font-weight: 500;
    margin-right: 70%;
  }
  .icon {
    margin-left: 20%;
    font-family: "Montserrat";
    font-weight: 500;
    background-color: red;

    padding-left: 22%;
    background: url("https://i.ibb.co/rbTBWwM/Camioncito-2x.png") no-repeat left;
    background-size: 24px;
  }
  .input1 {
    width: 20%;

    font-size: 60%;
  }

  input {
    width: 480%;
    height: 18px;
  }

  input::placeholder {
    font-weight: 500;
    color: #414242;
  }
  hr {
    border-top: 1px solid #a09a9e;
  }
  .registrate {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 66%;
  }
  .pasajeros {
    margin-left: 18%;
  }
}
</style>

<script>
// @ is an alias to /src
import axios from "axios";
import Toggle2 from "@/components/Toogle2.vue";
import Calendar from "@/components/ButtonCalendar.vue";
import PasajeroCount from "@/components/PasajerosCount.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Vue from "vue";

export default {
  name: "HomeView",
  props: {},
  data: () => ({
    bodyVariable: 'bodyFondo',
    vamosVariable: '#000000',
    origenVariable: 'origenDestino',
    pickersVariable: 'idaVuelta',
    pickerVueltaVariable: 'vuelta',
    sencilloredondo: 'sencilloredondo',
    iframe: false,
    ciudad_ida: [],
    ida: [],
    count: 0,
    terminal_origen_id: "",
    ida2: [],
    selectida: null,
    selectvuelta: null,
    loading: false,
    error: false,
    error_msg: "",
    date: null,
    date2: null,
    open: false,
    lang: {
      days: ["D", "L", "M", "Mi", "J", "V", "S"],
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    },
    noSesion: false
  }),
  
  async mounted() {
    this.iframe = this.inIframe();
    if(this.iframe){
      this.origenVariable = 'origenDestino2'
      this.pickersVariable = 'idaVuelta2'
      this.pickerVueltaVariable = 'vuelta2'
      this.bodyVariable = 'bodyFondo2'
      this.vamosVariable = 'vamos2'
      this.sencilloredondo = 'sencilloredondo2'
    }
    //this.noSesion = localStorage.access_token ? true : false;
    const access_token = localStorage.getItem('access_token');
    localStorage.clear();
    localStorage.setItem('access_token', access_token);
    if(access_token === 'null' || access_token === null){
      this.noSesion = true
    }else{
      this.noSesion = false
    }
    localStorage.setItem("value", 0);
    Promise.all([this.obtenercorridas()]).then(
      function () {}
    );
  },
  components: {
    Toggle2,
    AppButton,
    Calendar,
    PasajeroCount,
    DatePicker,
  },
  methods: {
    
    inIframe () {
      try {
        return window.self !== window.top;
      } catch (e) {
          return true;
      }
    },
    
    async obtenercorridas() {
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      axios
        .get("/terminales?page[number]=1&page[size]=1000", config)
        .then((response) => {
          //algoritmo para comparar ciudades con más de una terminal y modificar nombre de terminal
          var array_terminales = response.data.data;
          var terminales = array_terminales;
          array_terminales.forEach(terminal => {
            terminales.forEach((item, index) => {
              if(terminal.attributes.ciudad.attributes.nombre == item.attributes.ciudad.attributes.nombre){
                if(terminal.attributes.nombre != item.attributes.nombre){
                  terminales[index].terminal = item.attributes.nombre+', '+item.attributes.ciudad.attributes.nombre;
                }
              }
            });
          });
          this.ida = terminales;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    async validacion() {
      //fechaIda
      if(this.date != null){
        let dateIDA = this.date.toISOString().split("T")[0];
        localStorage.setItem("fecha_ida", dateIDA);
      }
      
      //fecha vuelta
      //let dateVuelta = null;
      if(this.date2 != null){
        let dateVuelta = this.date2.toISOString().split("T")[0];
        localStorage.setItem("fecha_vuelta", dateVuelta);
      }

      if (!this.selectida || !this.selectvuelta) {
        //this.error = true;
        //this.error_msg = "Necesitas seleccionar un origen y destino.";
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "Necesitas seleccionar un origen y destino.",
            duration: -1
          });
          return;
      } 

      if(this.count === 1 && this.date === null){
        //this.error = true;
        //this.error_msg = "Necesitas seleccionar una fecha de ida.";
        this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "Necesitas seleccionar una fecha de ida.",
            duration: -1
          });
          return;
      }

      if(this.count === 0 && this.date === null){
        //this.error = true;
        //this.error_msg = "Necesitas seleccionar una fecha de ida.";
        this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "Necesitas seleccionar una fecha de ida.",
            duration: -1
          });
          return;
      }

      if(this.count === 0 && this.date2 === null){
        //this.error = true;
        //this.error_msg = "Necesitas seleccionar una fecha de vuelta si el viaje es redondo.";
        this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "Necesitas seleccionar una fecha de vuelta si el viaje es redondo.",
            duration: -1
          });
          return;
      }
        
      //const value = parseInt(localStorage.getItem("total_pasajeros"));

        this.conversionFecha();
        this.error = false;
        
        localStorage.setItem("terminal_ida_id", this.selectida[0]);
        localStorage.setItem("terminal_vuelta_id", this.selectvuelta[0]);
        localStorage.setItem("Abreviatura_ida", this.selectida[3]);
        localStorage.setItem("Abreviatura_vuelta", this.selectvuelta[3]);
        localStorage.setItem("ida", [
          this.selectida[1] + ", " + this.selectida[2],
        ]);
        localStorage.setItem("vuelta", [
          this.selectvuelta[1] + ", " + this.selectvuelta[2],
        ]);

        const value = localStorage.getItem("value")
        const fecha_salida = localStorage.getItem("fecha_ida")
        const terminal_origen_id = localStorage.getItem("terminal_ida_id")
        const terminal_destino_id = localStorage.getItem("terminal_vuelta_id")
        const Abreviatura_ida = localStorage.getItem("Abreviatura_ida")
        const Abreviatura_vuelta = localStorage.getItem("Abreviatura_vuelta")
        const ida = localStorage.getItem("ida")
        const vuelta = localStorage.getItem("vuelta")
        const fecha_vuelta = localStorage.getItem("fecha_vuelta")

        if(this.iframe){
          const routeData = this.$router.resolve({name: 'paso1', query: {
            value: value,
            fecha_salida: fecha_salida, 
            terminal_origen_id: terminal_origen_id, 
            terminal_destino_id: terminal_destino_id,
            Abreviatura_ida: Abreviatura_ida,
            Abreviatura_vuelta: Abreviatura_vuelta,
            ida: ida,
            vuelta: vuelta,
            fecha_vuelta: fecha_vuelta,
            fecha_ida_before: this.date,
            fecha_vuelta_before: this.date2
          } });
          window.open(routeData.href, '_blank');
        }else{
          this.$router.push("/paso1");
        }
      
    },

    conversionFecha() {
      //Fecha ida convertir fecha en letra
      let fecha = this.date.toLocaleDateString("es-ES", {
        weekday: "long",
        day: "numeric",
        month: "long",
      });
      fecha = fecha.charAt(0).toUpperCase() + fecha.slice(1);
      fecha = fecha.replace(",", "");
      localStorage.setItem("fecha_ida_convertida", fecha);

      if (this.date2 != null) {
        let fechaVuelta = this.date2.toLocaleDateString("es-ES", {
          weekday: "long",
          day: "numeric",
          month: "long",
        });

        fechaVuelta = fechaVuelta.charAt(0).toUpperCase() + fechaVuelta.slice(1);
        fechaVuelta = fechaVuelta.replace(",", "");
        localStorage.setItem("fecha_vuelta_convertida", fechaVuelta);
      } else {
        localStorage.setItem("fecha_vuelta_convertida", null);
      }
    },

    click() {
      let switch_button = document.getElementById("switch-button");
      switch_button.addEventListener("click", function () {
        this.classList.toggle("switch-active");
      });
    },
    clickRedondo() {
      let switch_button = document.getElementById("switch-button");
      switch_button.addEventListener("click", function () {
        this.classList.toggle("switch-active");
      });
      this.count = 0;
      this.saveCount();
    },
    saveCount() {
      localStorage.setItem("value", this.count);
      //console.log (this.count);
    },
    clickSencillo() {
      let switch_button = document.getElementById("switch-button");
      switch_button.addEventListener("click", function () {
        this.classList.toggle("switch-active");
      });
      this.count = 1;
      this.saveCount();
    },
    hide() {
      {
        {
          var x = document.getElementById("myDIV");
          if (x.style.display === "none") {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
        }
      }
    },
  },
};
</script>
