<template>
  <div class="container">
    <div class="course">
      <div class="alert alert-primary" role="alert" v-if="error">
        {{ error_msg }}
      </div>
      <div class="preview"></div>
      <div class="info">
        <div
          v-for="(item, index) in lista"
          :key="item.id2"
          :value="[
            item.attributes.fecha_salida,
            item.attributes.precios.precio_en_linea,
            item.attributes.fecha_llegada,
          ]"
          @click="lista[index] = item.attributes.fecha_salida.replace('-', 'a')"
          class="hora1"
        >
          <div class="progress-wrapper">
            <div class="progress">
              <span class="progress-text2"> {{abreviatura_vuelta  }} </span>
            </div>
            <div class="slp">
              <span class="progress-text"> {{ abreviatura_ida }} </span>
            </div>
          </div>
          <v-row>
            <div class="hora2">
              <h6>
                {{ item.attributes.fecha_salida.substring(11, 16) }}
                {{
                  item.attributes.fecha_llegada.substring(11, 13) > 12
                    ? "PM"
                    : "AM"
                }}
              </h6>
            </div>
            <div class="hora1">
              <h6>
                {{ item.attributes.fecha_llegada.substring(11, 16) }}
                {{
                  item.attributes.fecha_llegada.substring(11, 13) > 12
                    ? "PM"
                    : "AM"
                }}
              </h6>
            </div>
            <div class="select">
              <button
                @click="
                  seleccionarSubcorrida(item.attributes.subcorrida_id);
                  seleccionarCorrida(item.attributes.corrida_id);
                  seleccionarRuta(item.attributes.ruta_id);
                  selecionarhora1(
                    item.attributes.fecha_salida.substring(11, 16)
                  );
                  selecionarhora2(
                  item.attributes.fecha_llegada.substring(11, 16)
                );
                "
              >
                <App-button :ancho="true" buttonText="Seleccionar" />
              </button>
            </div>
          </v-row>

          <div class="clases">
            <p class="p-trunc">
              {{ item.attributes.precios[0].nombre }}: &nbsp; ${{
                item.attributes.precios[0].precio_en_linea
              }}, &nbsp;&nbsp;&nbsp;{{ item.attributes.disponibilidad.P }}
              asientos disponibles
            </p>
            <p class="p-trunc2">
              {{ item.attributes.precios[1].nombre }}: &nbsp; ${{
                item.attributes.precios[1].precio_en_linea
              }}, &nbsp; &nbsp;{{ item.attributes.disponibilidad.E }} asientos
              disponibles
            </p>
            <p class="p-trunc3">
              {{ item.attributes.precios[2].nombre }}: &nbsp; ${{
                item.attributes.precios[2].precio_en_linea
              }},&nbsp; &nbsp; {{ item.attributes.disponibilidad.C }} asientos
              disponibles
            </p>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@media screen and (min-width: 350px) and (max-width: 600px) {
  .alert {
    margin-top: 2%;
    height: 120px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-primary {
    margin-left: 15%;
    margin-right: 20%;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    height: 120px;
  }

  hr {
    display: block;
    height: 0px;
    width: 220%;
    border: 0;
    border-top: 2.8px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: -22%;
  }
  .course {
    margin-top: 8%;
    background: rgba(248, 250, 252, 255);
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 95%;
    overflow: hidden;
    width: 100%;
    height: 800px;
    overflow-y: scroll;
    position: relative;
    top: 40%;
    left: 30%;
    transform: translate(-30%, -30%);
  }
  .course h6 {
    color: #010001;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .preview {
    width: 10px;
    background: rgba(248, 250, 252, 255);
  }

  .progress-text {
    color: #1a2b4b;
    margin-top: 15px;
    margin-left: 20%;
    font-weight: 600;
  }
  .progress {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    margin-top: 4%;
  }
  .progress-text2 {
    font-family: "Montserrat";
    font-weight: 600;
    margin-top: 15px;
    color: #1a2b4b;
    margin-left: -72%;
  }
  .hora1 {
    margin-top: 7px;
    margin-left: 10%;
  }
  .hora2 {
    margin-top: 7px;
    margin-left: -12% !important;
  }
  .slp {
    font-family: "Montserrat";
    font-size: 18px;
    color: #424243;
    margin-left: -35%;
    margin-top: -9%;
  }
  .clases {
    font-family: "Montserrat";
    line-height: 86%;
    color: #343434;
    font-weight: 500;
    margin-top: 6%;
    width: 400px;
    margin-left: -18% !important;
    font-size: 60%;
    margin-top: 5%;
    line-height: 20px;
  }
  .container {
    margin-top: 0%;
  }
  .p-trunc {
    margin-top: -5%;
    margin-left: 2%;
  }
  .p-trunc3 {
    margin-left: 2%;
  }

  .select {
    margin-top: -0%;
    margin-right: 10px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .alert {
    margin-top: 2%;
  height: 120px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-primary {
    margin-left: 15%;
    margin-right: 20%;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    height: 120px;
  }
  
  hr {
    display: block;
    height: 0px;
    width: 220%;
    border: 0;
    border-top: 2.8px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: -22%;
  }
  .course {
    margin-top: 8%;
    background: rgba(248, 250, 252, 255);
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 95%;
    overflow: hidden;
    width: 100%;
    height: 800px;
    overflow-y: scroll;
    position: relative;
    top: 40%;
    left: 30%;
    transform: translate(-30%, -30%);
  }
  .course h6 {
    color: #010001;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;

    letter-spacing: 1px;
  }
  .preview {
    width: 10px;
    background: rgba(248, 250, 252, 255);
  }

  .progress-text {
    color: #1a2b4b;
    margin-top: 15px;
    margin-left: 20%;
    font-weight: 600;
  }
  .progress {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    margin-top: 4%;
  }
  .progress-text2 {
    font-family: "Montserrat";
    font-weight: 600;
    margin-top: 15px;
    color: #1a2b4b;
    margin-left: -72%;
  }
  .hora1 {
    margin-top: 7px;
    margin-left: 10%;
  }
  .hora2 {
    margin-top: 7px;
    margin-left: 5%;
  }
  .slp {
    font-family: "Montserrat";
    font-size: 18px;
    color: #424243;
    margin-left: -35%;
    margin-top: -9%;
  }
  .clases {
    font-family: "Montserrat";
    line-height: 86%;
    color: #343434;
    font-weight: 500;
    margin-top: 6%;
    width: 400px;
    margin-left: -13%;
    font-size: 60%;
    margin-top: 5%;
    line-height: 20px;
  }
  .container {
    margin-top: 0%;
  }
  .p-trunc {
    margin-top: -5%;
    margin-left: 2%;
  }
  .p-trunc3 {
    margin-left: 2%;
  }

  .select {
    margin-top: -16%;
    margin-left: 100%;
    margin-right: 20%;
  }
}
@media screen and (max-width: 600px) {

  .alert {
    margin-top: 2%;
  height: 120px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-primary {
    margin-left: 4%;
    margin-right: 4%;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  hr {
    display: block;
    height: 0px;
    width: 220%;
    border: 0;
    border-top: 2.5px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: -12%;
  }
  .course {
    margin-top: 200px;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 100%;
    overflow: hidden;
    width: 140%;
    position: relative;
    height: 600px;
    left: 30%;
   overflow-y: scroll;

    transform: translate(-30%, -30%);
  }
  .course h6 {
    color: #010001;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;

    letter-spacing: 1px;
  }
  .preview {
    width: 10px;
    background: rgba(248, 250, 252, 255);
  }

  .progress-text {
    font-weight: 600;
    color: #1a2b4b;
    margin-left: 14%;
  }
  .progress {
    color: #1a2b4b;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    margin-top: 7%;
  }
  .progress-text2 {
    font-weight: 600;
    font-family: "Montserrat";
    color: #1a2b4b;
    margin-left: -88%;
  }
  .hora1 {
    margin-top: 7px;
    margin-left: 10%;
  }
  .hora2 {
    margin-top: 7px;
    margin-left: -2%;
  }
  .slp {
    font-family: "Montserrat";
    font-size: 18px;
    color: #424243;
    margin-left: -35%;
    margin-top: -8.9%;
  }
  .clases {
    font-family: "Montserrat";
    line-height: 86%;
    color: #343434;
    font-weight: 500;
    margin-top: 6%;
    width: 400px;
    margin-left: -16%;
    font-size: 60%;
    margin-top: 6%;
    line-height: 20px;
  }
  
  .p-trunc {
    margin-top: -5%;
    margin-left: 2%;
  }
  .p-trunc3 {
    margin-left: 3%;
  }
  .select {
    margin-top: -21%;
    margin-left: 55%;
  }
  .horas {
    margin-left: 4%;
  }
  
}
@media screen and (min-width: 801px) and (max-width: 1264px) {
  .alert {
    margin-top: 1%;
    padding: 0px;
    margin-bottom: 10px;
    border: 5px solid transparent;
    border-radius: 10px;
    height: 120px;
  }
  .alert-primary {
    margin-left: 20%;
    margin-right: 20%;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  hr {
    display: block;
    height: 0px;
    width: 250%;
    border: 0;
    border-top: 3px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: -22%;
  }
  .course {
    margin-top: 10%;
    background: rgba(248, 250, 252, 255);
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 95%;
    overflow: hidden;
    width: 100%;
    height: 700px;
    position: relative;
    overflow-y: scroll;
    top: 0%;
    left: 30%;
    transform: translate(-30%, -30%);
  }
  .course h6 {
    color: #010001;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;

    letter-spacing: 1px;
  }
  .preview {
    width: 10px;
    background: rgba(248, 250, 252, 255);
  }

  .progress-text {
    color: #1a2b4b;
    margin-top: 15px;
    margin-left: 25%;
    font-weight: 600;
  }
  .progress {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    margin-top: 4%;
  }
  .progress-text2 {
    font-family: "Montserrat";
    font-weight: 600;
    margin-top: 15px;
    color: #1a2b4b;
    margin-left: -73%;
  }
  .hora1 {
    margin-top: 7px;
    margin-left: 10%;
  }
  .hora2 {
    margin-top: 7px;
    margin-left: 5%;
  }
  .slp {
    font-family: "Montserrat";
    font-size: 18px;
    color: #424243;
    margin-left: -35%;
    margin-top: -9%;
  }
  .clases {
    font-family: "Montserrat";
    line-height: 86%;
    color: #343434;
    font-weight: 500;
    margin-top: 6%;
    width: 400px;
    margin-left: -13%;
    font-size: 60%;
    margin-top: 5%;
    line-height: 20px;
  }
  .container {
    margin-top: 0%;
  }
  .p-trunc {
    margin-top: -5%;
    margin-left: 2%;
  }
  .p-trunc3 {
    margin-left: 2%;
  }

  .select {
    margin-top: -16%;
    margin-left: 100%;
    margin-right: 20%;
  }
}
@media screen and (min-width: 1201px) {
  .alert {
    margin-top: 20%;
    padding: 30px;
    margin-bottom: 10px;
    border: 5px solid transparent;
    border-radius: 10px;
    height: 120px;
  }
  .alert-primary {
    margin-left: 20%;
    margin-right: 20%;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  hr {
    display: block;
    height: 20px;
    width: 360%;
    border: 0;
    border-top: 3px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: -12%;
  }
  .course {
    margin-top: 3%;
    background: rgba(248, 250, 252, 255);
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 100%;
    overflow: hidden;
    width: 115%;
    height: 600px;
    position: relative;
    top: 40%;
    left: 30%;
    overflow-y: scroll;

    transform: translate(-30%, -30%);
  }
  .course h6 {
    color: #010001;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .preview {
    width: 0px;
    background: rgba(248, 250, 252, 255);
  }

  .progress-text {
    color: #1a2b4b;
    margin-top: 15px;
    margin-left: 15%;
    font-weight: 600;
  }
  .progress {
    color: #1a2b4b;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 18px;
    margin-top: 5%;
  }
  .progress-text2 {
    font-family: "Montserrat";
    margin-top: 15px;
    color: #1a2b4b;
    margin-left: -83%;
  }
  .hora1 {
    margin-top: 7px;
    margin-left: 10%;
  }
  .hora2 {
    margin-top: 7px;
    margin-left: 0%;
  }
  .slp {
    font-family: "Montserrat";
    font-size: 18px;
    color: #3d3d3d;
    margin-left: -35%;
    margin-top: -9%;
  }
  .clases {
    font-family: "Montserrat";
    line-height: 86%;
    color: #343434;
    font-weight: 500;
    margin-top: 10%;
    width: 400px;
    margin-left: -12%;
    font-size: 14px;
    margin-top: 5%;
  }
  .container {
    margin-top: 0%;
  }
  .p-trunc {
    margin-top: 0%;
    margin-left: -19%;
  }
  .p-trunc2 {
    margin-top: -3%;
    margin-right: -150%;
  }
  .p-trunc3 {
    margin-top: -3%;
    margin-right: -320%;
  }
  .select {
    margin-top: -16%;
    margin-left: 215%;
    margin-right: 20%; 
  }
}
@media screen and (min-width: 1366px) {
  .alert {
    margin-top: 20%;
    padding: 30px;
    margin-bottom: 10px;
    border: 5px solid transparent;
    border-radius: 10px;
    height: 120px;
  }
  .alert-primary {
    margin-left: 20%;
    margin-right: 20%;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  hr {
    display: block;
    height: 20px;
    width: 360%;
    border: 0;
    border-top: 3px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: -12%;
  }
  .course {
    margin-top: 3%;
    background: rgba(248, 250, 252, 255);
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 87%;
    overflow: hidden;
    width: 115%;
    height: 600px;
    position: relative;
    top: 40%;
    left: 32%;
    overflow-y: scroll;
    transform: translate(-30%, -30%);
  }
  .course h6 {
    color: #010001;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .preview {
    width: 0px;
    background: rgba(248, 250, 252, 255);
  }

  .progress-text {
    color: #1a2b4b;
    margin-top: 15px;
    margin-left: 15%;
    font-weight: 600;
  }
  .progress {
    color: #1a2b4b;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 18px;
    margin-top: 5%;
  }
  .progress-text2 {
    font-family: "Montserrat";
    margin-top: 15px;
    color: #1a2b4b;
    margin-left: -83%;
  }
  .hora1 {
    margin-top: 7px;
    margin-left: 10%;
  }
  .hora2 {
    margin-top: 7px;
    margin-left: 0%;
  }
  .slp {
    font-family: "Montserrat";
    font-size: 18px;
    color: #3d3d3d;
    margin-left: -35%;
    margin-top: -9%;
  }
  .clases {
    font-family: "Montserrat";
    line-height: 86%;
    color: #343434;
    font-weight: 500;
    margin-top: 10%;
    width: 400px;
    margin-left: -4%;
    font-size: 14px;
    margin-top: 5%;
  }
  .container {
    margin-top: 0%;
  }
  .p-trunc {
    margin-top: 0%;
    margin-left: -25%;
  }
  .p-trunc2 {
    margin-top: -3%;
    margin-right: -160%;
  }
  .p-trunc3 {
    margin-top: -3%;
    margin-right: -340%;
  }
  .select {
    margin-top: -16%;
    margin-left: 245%;
  }
}
@media screen and (min-width: 1920px) {
  .alert {
    margin-top: 20%;
    padding: 30px;
    margin-bottom: 10px;
    border: 5px solid transparent;
    border-radius: 10px;
    height: 120px;
  }
  .alert-primary {
    margin-left: 20%;
    margin-right: 20%;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  hr {
    display: block;
    height: 20px;
    width: 560%;
    border: 0;
    border-top: 3px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-left: -12%;
  }
  .course {
    margin-top: 0%;
    background: rgba(248, 250, 252, 255);
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 73.8%;
    overflow: hidden;
    width: 100%;
    height: 600px;
    position: relative;
    top: 40%;
    left: 35%;
    overflow-y: scroll;
    transform: translate(-30%, -30%);
  }
  .course h6 {
    color: #010001;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .preview {
    width: 0px;
    background: rgba(248, 250, 252, 255);
  }

  .progress-text {
    color: #1a2b4b;
    margin-top: 15px;
    margin-left: 15%;
    font-weight: 600;
  }
  .progress {
    color: #1a2b4b;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 18px;
    margin-top: 5%;
  }
  .progress-text2 {
    font-family: "Montserrat";
    margin-top: 15px;
    color: #1a2b4b;
    margin-left: -83%;
  }
  .hora1 {
    margin-top: 7px;
    margin-left: 10%;
  }
  .hora2 {
    margin-top: 7px;
    margin-left: 0%;
  }
  .slp {
    font-family: "Montserrat";
    font-size: 18px;
    color: #3d3d3d;
    margin-left: -35%;
    margin-top: -9%;
  }
  .clases {
    font-family: "Montserrat";
    line-height: 86%;
    color: #343434;
    font-weight: 500;
    margin-top: 10%;
    width: 100%;
    margin-left: 3%;
    font-size: 16px;
    margin-top: 5%;
  }
  .container {
    margin-top: 0%;
  }
  .p-trunc {
    margin-top: 0.5%;
    margin-left: -19%;
  }
  .p-trunc2 {
    margin-top: -4%;
    margin-right: -210%;
  }
  .p-trunc3 {
    margin-top: -4%;
    margin-right: -443%;
  }
  .select {
    margin-top: -16%;
    margin-left: 280%;
    margin-right: 20%;
  }
}
</style>



<script>
// @ is an alias to /src

import AppButton from "@/components/Seleccionar.vue";
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      lista: [],
      lista_atributes: [],
      abreviatura_ida: "",
      abreviatura_vuelta: "",
      precios: [],
      error: false,
      error_msg: "",
    };
  },
  components: {
    AppButton,
  },
  async mounted() {
    Promise.all([this.obtenerdata(), this.obtenersubcorrida()]).then(
      function () {}
    );
  },
  methods: {
    seleccionarCorrida(id) {
      localStorage.setItem("corrida_vuelta_id", id);
    },
    seleccionarRuta(id) {
      localStorage.setItem("ruta_vuelta_id", id);
    },
    selecionarhora1(hr) {
      const fecha_vuelta = localStorage.getItem("fecha_vuelta");
      localStorage.setItem("fecha_vuelta_hora", fecha_vuelta + " " + hr);
      localStorage.setItem("hr3", hr);
    },
     selecionarhora2(hr2) {
      localStorage.setItem("hr4", hr2);
    },
    seleccionarSubcorrida(id) {
      localStorage.setItem("subcorrida_vuelta_id", id);
      this.$router.push("/paso4");
    },
    obtenerdata() {
      this.abreviatura_ida = localStorage.getItem("Abreviatura_ida");
      this.abreviatura_vuelta = localStorage.getItem("Abreviatura_vuelta");
      if (this.lista.length == 1) {
      } else {
      }
    },
    obtenersubcorrida() {
      const fecha_vuelta = localStorage.getItem("fecha_vuelta");
      //console.log('llega usando fecha vuelta')
      //console.log(fecha_vuelta);
      if (fecha_vuelta == null) {
        this.$router.push("/");
      } else {
        let config = {
          headers: {
            Accept: "application/vnd.api+json",
          },
        };
        axios
          .get(
            "/subcorridas?page[number]=1&page[size]=100",
            {
              params: {
                fecha_salida: localStorage.getItem("fecha_vuelta"),
                terminal_origen_id: localStorage.getItem("terminal_vuelta_id"),
                terminal_destino_id: localStorage.getItem("terminal_ida_id"),
              },
            },
            config
          )
          .then((response) => {
            const lista = response.data.data;
            const lista_atributes = response.data.data[0].atributtes;
            this.lista_atributes = lista_atributes;
            this.lista = lista;
          })
          .catch((error) => {
            console.log(error);
            //console.log("Aqui estuvo el error de get info de subcorrida");
            this.error = true;
            this.error_msg = "No hay viajes disponibles. Intente nuevamente con otras fechas.";
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: "No hay viajes disponibles. Intente nuevamente con otras fechas.",
              duration: -1
            });
          });
      }
    },
  },
};
</script>