<template>
  <div data-app>
    <v-col>
      <Stepper6 />
    </v-col>
    <v-row>
      <div class="titulo">
        <Titulo Titulo="Resumen de tu viaje" />
      </div>
      <div class="atras">
        <button @click="resetValue()">
          <Back/>
        </button>
      </div>
    </v-row>
    <div class="subtitulo3">
      <Subtitulo3 subTitulo3="Ayudanos revisando tus datos" />
    </div>
    <RutaViaje v-if="value == 1" :boletos_ida="boletos_ida" />
    <RutaViajeVuelta v-if="value == 0" :boletos_ida="boletos_ida" :boletos_vuelta="boletos_vuelta"  />
    <ConfirmarContacto />
    <div class="total">
      <Subtitulo6 />
    </div>
    <PagarCon v-if="checkbox" :boletos_ida="boletos_ida" :boletos_vuelta="boletos_vuelta" />
    <div class="container acuerdo">
    <v-checkbox v-model="checkbox">
      <template v-slot:label>
        <div>
          Estoy de acuerdo con los
            <a
              @click="modalTerminos = true"
              class="terminos"
              style="font-weight: bold !important; color: #0568A0 !important;"
            >
            Términos y Condiciones
            </a>
            y
            <a 
              @click="modalPrivacidad = true"
              style="font-weight: bold !important; color: #0568A0 !important;"
            >
            Política de Privacidad.
            </a>
        </div>
      </template>
    </v-checkbox>
    <v-dialog
      v-model="modalPrivacidad"
      width="auto"
    >
      <v-card>
        <vue-pdf-embed :source="aviso" :width="500" :height="375"/>
        <!--
        <embed src="/documents/OMNIBUS UNIDOS REGIOMONTANOS S DE RL DE CV,AVISO DE PRIVACIDAD.pdf" width="500" height="375" type="application/pdf">
        -->
          <v-card-actions>
          <v-btn class="botonModal" color="success" block @click="modalPrivacidad = false">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalTerminos"
      width="auto"
    >
      <v-card>
        <vue-pdf-embed :source="terminos" :width="500" :height="375"/>
        <!--
        <embed src="/documents/OMNIBUS UNIDOS REGIOMONTANOS S DE RL DE CV, CONTRATO DE ADHESION Términos y Condiciones.pdf" width="500" height="375" type="application/pdf">
        -->
          <v-card-actions>
          <v-btn class="botonModal" color="success" block @click="modalTerminos = false">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </div>
</template>
<style scoped lang="less">
.botonModal{
  background-color: #0568A0 !important;
  color: white !important;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: -8%;
    margin-top: 3%;
  }
  .atras {
    margin-top: 15%;
    margin-left: 7%;
  }
  .subtitulo3 {
    margin-top: -7%;
    font-size: 16px;
    margin-left: -20%;
  }

  .contacto {
    font-size: 18px;
    margin-left: -16%;
  }
  .total {
    font-size: 18px;
    margin-left: -14%;
  }

  .acuerdo {
    margin-left: 0%;
  }
}
@media screen and (min-width: 481px) and (max-width: 760px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: 25%;
    margin-top: 9%;
  }
  .atras {
    margin-top: 10%;
    margin-left: 3%;
  }
  .subtitulo3 {
    margin-top: 3%;
    font-size: 16px;
    margin-left: -6%;
  }

  .contacto {
    font-size: 18px;
    margin-left: -16%;
  }
  .total {
    font-size: 18px;
    margin-left: 10%;
  }
  .acuerdo {
    margin-left: 8%;
  }
}
@media screen and (min-width: 761px) and (max-width: 1200px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: 3%;
    margin-top: -4%;
  }
  .atras {
    margin-top: 2%;
    margin-left: -150px;
  }
  .subtitulo3 {
    margin-top: 3%;
    font-size: 20px;
    margin-left: -10px;
  }

  .contacto {
    font-size: 18px;
    margin-left: -16%;
  }
  .total {
    font-size: 18px;
    margin-left: 0%;
  }
  .acuerdo {
    margin-left: 10%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1365px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: 3%;
    margin-top: -2%;
  }
  .atras {
    margin-top: -1.5%;
    margin-left: 20px;
  }
  .subtitulo3 {
    margin-top: 3%;
    font-size: 20px;
    margin-left: -70px;
  }

  .contacto {
    font-size: 18px;
    margin-left: -16%;
  }
  .total {
    font-size: 18px;
    margin-left: -4%;
  }
  .acuerdo {
    margin-left: 23.5%;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: 3%;
    margin-top: -2%;
  }
  .atras {
    margin-top: -1.5%;
    margin-left: 20px;
  }
  .subtitulo3 {
    margin-top: 3%;
    font-size: 20px;
    margin-left: -70px;
  }

  .contacto {
    font-size: 18px;
    margin-left: -16%;
  }
  .total {
    font-size: 18px;
    margin-left: -4%;
  }
  .acuerdo {
    margin-left: 26%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: 3%;
    margin-top: -2%;
  }
  .atras {
    margin-top: -1.5%;
    margin-left: 20px;
  }
  .subtitulo3 {
    margin-top: 3%;
    font-size: 20px;
    margin-left: -70px;
  }

  .contacto {
    font-size: 18px;
    margin-left: -16%;
  }
  .total {
    font-size: 18px;
    margin-left: -4%;
  }
  .acuerdo {
    margin-left: 28%;
  }
}
@media screen and (min-width: 1920px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: 3%;
    margin-top: -2%;
  }
  .atras {
    margin-top: -30px;
    margin-left: 10px;
  }
  .subtitulo3 {
    margin-top: 7%;
    font-size: 20px;
    margin-left: 0px;
  }

  .contacto {
    font-size: 18px;
    margin-left: -16%;
  }
  .total {
    font-size: 18px;
    margin-left: 0%;
  }
  .acuerdo {
    margin-left: 34%;
  }
}
</style>
<script>
import CryptoJS from 'crypto-js';
import Stepper6 from "@/components/Stepper/Stepper6.vue";
import Subtitulo6 from "@/components/SubTitle6.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Back from "@/components/Back.vue";
import Input from "@/components/InputVue.vue";
import RutaViaje from "@/components/RutaViaje.vue";
import RutaViajeVuelta from "@/components/RutaViajeVuelta.vue";
import ConfirmarContacto from "@/components/ConfirmarContacto.vue";
import PagarCon from "@/components/PagarCon.vue";
import Titulo from "@/components/title.vue";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: "Paso6",
  props:{
    boletos_ida: { required: false },
    boletos_vuelta: { required: false }
  },
  components: {
    Stepper6,
    Input,
    Subtitulo3,
    Back,
    AppButton,
    RutaViaje,
    ConfirmarContacto,
    PagarCon,
    Subtitulo6,
    Titulo,
    RutaViajeVuelta,
    VuePdfEmbed
  },
  data() {
    return {
      data: null,
      email: "",
      value: localStorage.getItem("value"),
      checkbox: false,
      modalTerminos: false,
      modalPrivacidad: false,
      aviso: '/documents/AvisoDePrivacidad.pdf',
      terminos: '/documents/TerminosYCondiciones.pdf'
    };
  },
  mounted() {},
  created() {
    const venta_exitosa = localStorage.getItem("venta_exitosa");
    //console.log('venta exitosa'+venta_exitosa)
    if(venta_exitosa){
      this.$router.push("/");
    }
    let storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      this.email = storedEmail;
    }
  },
  methods: {
    decryptData(encryptedData) {
      const secretKey = "my-secret-key"; // clave secreta para el cifrado
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return decryptedData.toString(CryptoJS.enc.Utf8);
    },
     encryptData(data) {
      const secretKey = 'my-secret-key'; // clave secreta para el cifrado
      const encryptedData = CryptoJS.AES.encrypt(data, secretKey);
      return encryptedData.toString();
    },
    resetValue() {
      if (this.value == 0) {
        this.$router.push("/paso5");
      } else {
        this.boletos = JSON.parse(this.boletos_ida);
          const totalDescuentoValor = this.boletos.reduce(
            (acumulador, boleto) => {
              if (boleto.descuento && boleto.descuento.valor) {
                return acumulador + parseFloat(boleto.descuento.valor);
              }
              return acumulador;
            },
            0
          );
          const totalValor = this.boletos.reduce(
            (acumulador, boleto) => {
              if ( boleto.precio_lista) {
                return acumulador + parseFloat(boleto.precio_lista);
              }
              return acumulador;
            },
            0
          );
        //Regresa viaje ida
        const total = parseFloat(totalValor) - (totalDescuentoValor);
        const encryptedSuma = this.encryptData(total.toString());
      localStorage.setItem('key', encryptedSuma);
      this.$router.push("/paso5");
      }
    },
  },
};
</script>
